import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { Cotizacion } from '../../shared/models';
import { map, tap } from 'rxjs/operators';
import { BzStorageService, BzUserBehaviourService } from '@balanz/services';
import { EnvironmentService } from '../services/environment.service';
import { Recomendacion } from '../../shared/models/recomendacion.model';
import { calcularVarPorcentual, calcularVarAbsoluta } from '../../utils/variacion-cot';
import Instrumentos from './../../shared/constants/instrumentos.json';
import TiposInstrumentos from './../../shared/constants/tiposInstrumentos.json';
import { InstrumentView } from './../../shared/models/instrument-view';
import { CuentaService } from './cuenta.service';
import { MinutasMepInfo } from './../../shared/models/minutas-mep-info.model';

export type DisplayMode = 'table' | 'card';

export interface TablaCotizacionFilters {
    plazos?: any;
    descripcion?: string;
    monedas?: any;
    mostrarConPrecio?: boolean;
    eventoptfrom?: string;
    tipoPerfil?: any;
    sector?: string;
    industrias?: string;
}

@Injectable()
export class CotizacionesClientService {
    refreshTime = this._envService.env.timeRefreshDataMinutes * 60000;
    scrollToTable = 400;
    plazos: any = [
        { text: '48 hs', value: '48hs' },
        { text: '24 hs', value: '24hs' },
        { text: 'CI', value: 'CI' }
    ];
    cotizacionesRouteMaps = [];
    private _apiURL = this._envService.env.BaseUrlService;
    private _apiUrlInsights = this._envService.env.baseUrlInsights;
    private _basePath = this._envService.env.BaseUrlService + '/cotizaciones';
    private displayModeSubject$: BehaviorSubject<DisplayMode>;
    private cotizacionesSub: ReplaySubject<any[]> = new ReplaySubject(1);
    public lastViewed$: BehaviorSubject<InstrumentView[]> = new BehaviorSubject(null);
    private monedas: any[] = [
        { text: 'Pesos', value: 'Pesos', checked: true },
        { text: 'Dólar MEP', value: 'Dolar MEP', checked: false },
        { text: 'Dólar Cable', value: 'Dolar Cable', checked: false }
    ];
    get monedasAll(): any[] {
        return this.monedas;
    }

    get tabsRoutes(): any[] {
        return this.cotizacionesRouteMaps;
    }

    private _refreshTimes = {};
    private _cachedRecomendaciones = {};
    private likedInstruments: string[];

    constructor(
        private http: HttpClient,
        private storage: BzStorageService,
        private _envService: EnvironmentService,
        private _userBehaviourService: BzUserBehaviourService,
        private _cuentaService: CuentaService
    ) {
        const displayMode = this.storage.get('display_mode_cotizaciones') || 'table';
        this.displayModeSubject$ = new BehaviorSubject<DisplayMode>(displayMode);

        Instrumentos.forEach(item => {
            if (item.isTab) {
                this.cotizacionesRouteMaps[item.name] = item.valor;
            }
        });
    }

    hasInstrument(tab, isBci) {
        return Instrumentos.filter(i => i.bci === isBci && i.name === tab).length > 0;
    }

    getTipoInstrumentoPlazosByName(instrumentoPlazos, permisos): string {
        if (instrumentoPlazos === 'renta fija') {
            instrumentoPlazos = 'bonos';
        }
        const resultados = this.getTipoInstrumentoByName(instrumentoPlazos);
        let plazos;
        if (resultados.length > 0) {
            permisos.filter(perm => {
                if (perm.id === resultados[0].idPermiso) {
                    plazos = perm.opciones.plazosHabilitados;
                }
            });
        }

        return plazos;
    }

    getTiposInstByPanel(panel: number) {
        return TiposInstrumentos.filter(tipo => tipo.panel === panel);
    }

    getTipoInstrumentoByName(tipo) {
        return Instrumentos.filter(instrumento => instrumento.name.toLowerCase() === tipo.toLowerCase());
    }

    getTipoInstrumentoByPanel(panel) {
        return Instrumentos.filter(instrumento => instrumento.panel === panel);
    }

    getTipoInstrumentoByValor(valor) {
        return Instrumentos.filter(instrumento => instrumento.valor.toLowerCase() === valor.toLowerCase());
    }
    getAcciones(indice, params): Observable<any> {
        let acciones: any = [];
        acciones = this.http.get<Cotizacion[]>(`${this._basePath}/acciones/${indice}`, { params: { ...params } });
        const accionesConVariaciones = acciones.pipe(
            map((accion: any) => {
                accion.cotizaciones.map(data => {
                    data.variacionAbs = calcularVarAbsoluta(data);
                    data.variacionPorcentual = calcularVarPorcentual(data);
                });
                return accion;
            })
        );
        return accionesConVariaciones;
    }

    getPanel(indice, params): Observable<any> {
        let panel: any = [];
        panel = this.http.get<Cotizacion[]>(`${this._basePath}/panel/${indice}`, {
            params: { ...params }
        });
        const panelesConVariaciones = panel.pipe(
            map((p: any) => {
                p.cotizaciones.map(data => {
                    data.variacionAbs = calcularVarAbsoluta(data);
                    data.variacionPorcentual = calcularVarPorcentual(data);
                });
                return p;
            })
        );
        return panelesConVariaciones;
    }

    getCedears(params): Observable<any> {
        let cedears: any = [];
        cedears = this.http.get<Cotizacion[]>(`${this._basePath}/cedears`, {
            params: { ...params }
        });
        const cedearsConVariaciones = cedears.pipe(
            map((cedear: any) => {
                cedear.cotizaciones.map(data => {
                    data.variacionAbs = calcularVarAbsoluta(data);
                    data.variacionPorcentual = calcularVarPorcentual(data);
                });
                return cedear;
            })
        );
        return cedearsConVariaciones;
    }

    getFondosComunesDeInversion(params): Observable<any> {
        return this.http.get<Cotizacion[]>(`${this._basePath}/fci`, {
            params: { ...params }
        });
    }

    getOpciones(params): Observable<any> {
        let opciones: any = [];

        opciones = this.http.get<Cotizacion[]>(`${this._basePath}/opciones`, {
            params: { ...params, avoidAuthRedirect: true }
        });
        const opcionesConVariaciones = opciones.pipe(
            map((opcion: any) => {
                opcion.cotizaciones.map(data => {
                    data.variacionAbs = calcularVarAbsoluta(data);
                    data.variacionPorcentual = calcularVarPorcentual(data);
                });
                return opcion;
            })
        );
        return opcionesConVariaciones;
    }

    getTreasuries(params): Observable<any> {
        let treasuries: any = [];

        treasuries = this.http.get<Cotizacion[]>(`${this._basePath}/marketplace/64`, {
            params: { ...params, avoidAuthRedirect: true }
        });
        const treasuriesConVariaciones = treasuries.pipe(
            map((treasurie: any) => {
                treasurie.cotizaciones.map(data => {
                    data.variacionAbs = calcularVarAbsoluta(data);
                    data.variacionPorcentual = calcularVarPorcentual(data);
                });
                return treasurie;
            })
        );
        return treasuriesConVariaciones;
    }

    getWatchlists(idWatchlist, params): Observable<any> {
        return this.http
            .get<any>(`${this._basePath}/watchlists/${idWatchlist}`, { params })
            .pipe(
                map((data: any) => ({
                    idWatchlist: data.id,
                    name: data.name,
                    descripcion: data.description,
                    cotizaciones: data.cotizaciones.map(cotizacion => ({
                        ...cotizacion,
                        Tipo: cotizacion.Tipo === 'Renta Fija' ? 'Bonos' : cotizacion.Tipo
                    }))
                }))
            );
    }

    getCotizacionPorInstrumento(params): Observable<any> {
        return this.http.get(`${this._envService.env.BaseUrlService}/cotizacioninstrumento`, {
            params: { ...params }
        });
    }

    // Devuelve mismo payload que /cotizacioninstrumento con precios en tiempo real costo per-query así que sólo para panel
    getCotizacionInstrumentoInternacionalRealTime(params): Observable<any> {
        return this.http.get<any>(`${this._apiURL}/cotizacionoperar`, {
            params: { ...params }
        });
    }

    getCotizacionTreasuries(params): Observable<any> {
        return this.http.get<any>(`${this._apiURL}/cotizacionoperar/treasuries`, {
            params: { ...params }
        });
    }

    getCotizacionPorFondo(ticker): Observable<any> {
        return this.http.get(`${this._envService.env.BaseUrlService}/cotizacionhistorico?ticker=${ticker}`);
    }
    getRendimientoHistoricoFondo(ticker): Observable<any> {
        return this.http.get(`${this._envService.env.BaseUrlService}/rendimientohistorico/${ticker}`);
    }
    getPacks(panel, token): Observable<any> {
        return this.http.get(`${this._envService.env.BaseUrlService}/cotizaciones/panel/${panel}?token=${token}`);
    }

    getDetalleInvesment(ticker): Observable<any> {
        return this.http.get(`${this._envService.env.BaseUrlService}/sinteticos/detalle?Ticker=${ticker}`);
    }

    getPacksRelacionados(idSintetico) {
        return this.http.get(`${this._envService.env.BaseUrlService}/sinteticos/relacionados/${idSintetico}`);
    }

    getOperaciones(params): Observable<any> {
        return this.http.get<Cotizacion[]>(`${this._basePath}/opciones`, {
            params: { ...params }
        });
    }

    getExterior(indice, params): Observable<any> {
        return this.http.get<Cotizacion[]>(`${this._basePath}/exterior/${indice}`, {
            params: { ...params }
        });
    }

    getMoneda(tipoMeneda): Observable<any> {
        return this.http.get(`${this._envService.env.BaseUrlService}/cotizacionmoneda/${tipoMeneda}`);
    }

    getCotizacionCuotaParte(ticker: string): Observable<any> {
        const idCuenta = this._cuentaService.getCuentaSeleccionada().idCuenta;
        return this.http.get(`${this._envService.env.BaseUrlService}/cotizacioncuotaparte`, {
            params: { ticker, idCuenta }
        });
    }

    displayMode$(): Observable<DisplayMode> {
        return this.displayModeSubject$.asObservable();
    }

    toggleDisplayMode(): void {
        const currentMode = this.displayModeSubject$.value;
        const mode = currentMode === 'table' ? 'card' : 'table';
        this.storage.set('display_mode_cotizaciones', mode);
        this.displayModeSubject$.next(mode);
    }

    setCotizaciones(cotizaciones: any[]): void {
        this.cotizacionesSub.next(cotizaciones);
    }

    listenCotizaciones(): Observable<any[]> {
        return this.cotizacionesSub.asObservable();
    }

    filterCotizaciones(
        cotizacionesRaw: any[],
        instrumento: string,
        filtros: TablaCotizacionFilters,
        search: string
    ): any[] {
        return cotizacionesRaw
            .filter(cotizacion => {
                if (
                    instrumento === 'Futuros' ||
                    instrumento === 'Opciones' ||
                    instrumento === 'latam' ||
                    instrumento === 'US Treasuries' ||
                    instrumento === 'FCI'
                ) {
                    return true;
                }
                if (instrumento === 'Fondos') {
                    if (filtros.monedas) {
                        const _filtro = filtros.monedas.map(item => (item.toLowerCase() === 'pesos' ? 1 : 2));
                        if (_filtro.some(item => item === cotizacion['idMoneda'])) {
                            if (filtros.plazos) {
                                if (filtros.plazos.some(item => item === cotizacion['idPlazo'])) {
                                    if (filtros.tipoPerfil) {
                                        return filtros.tipoPerfil.some(
                                            item => item.toLowerCase() === cotizacion['perfilInversor']?.toLowerCase()
                                        );
                                    }
                                    return true;
                                }
                            }
                        }
                    }
                    if (filtros.plazos) {
                        if (filtros.plazos.some(item => item === cotizacion['idPlazo'])) {
                            if (filtros.monedas) {
                                const _filtro = filtros.monedas.map(item => (item.toLowerCase() === 'pesos' ? 1 : 2));
                                if (_filtro.some(item => item === cotizacion['idMoneda'])) {
                                    if (filtros.tipoPerfil) {
                                        return filtros.tipoPerfil.some(
                                            item => item.toLowerCase() === cotizacion['perfilInversor']?.toLowerCase()
                                        );
                                    }
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                }
                if (filtros.sector && filtros.sector !== 'Todos') {
                    if (cotizacion['industrySector'] !== filtros.sector) {
                        return false;
                    }
                }
                if (filtros.industrias && filtros.industrias !== 'Todos') {
                    if (cotizacion['industryGroup'] !== filtros.industrias) {
                        return false;
                    }
                }
                let plazoValido = true;
                let monedaValida = true;
                if (filtros.plazos) {
                    plazoValido = cotizacion['plazo'] === filtros.plazos;
                }
                if (filtros.monedas) {
                    monedaValida = filtros.monedas.some(item => item.toLowerCase() === cotizacion['mo'].toLowerCase());
                }
                return plazoValido && monedaValida;
            })
            .filter(cotizacion => {
                const descripcion = (cotizacion?.descripcion || cotizacion?.description)?.toLowerCase();
                const ticker = cotizacion.ticker?.toLowerCase();
                if (search === '') {
                    return true;
                }
                if (
                    (descripcion && descripcion.includes(search.toLowerCase())) ||
                    (ticker && ticker.includes(search.toLowerCase()) && search !== '')
                ) {
                    return true;
                }
            })
            .filter(cotizacion => {
                if (filtros.mostrarConPrecio) {
                    return cotizacion['pc'] !== -1 || cotizacion['pv'] !== -1;
                }
                return true;
            });
    }

    getRecomendacion(path): Observable<Recomendacion> {
        const currentTime = Date.now();
        const refreshTime = this._refreshTimes[path];

        if (refreshTime && currentTime - refreshTime < this.refreshTime) {
            return of(this._cachedRecomendaciones[path]);
        } else {
            return this.http
                .get<Recomendacion>(path, { params: { avoidAuthRedirect: 'true' } })
                .pipe(
                    tap(data => {
                        this._cachedRecomendaciones[path] = data;
                        this._refreshTimes[path] = currentTime;
                    })
                );
        }
    }
    getBonosLatam(indice, params) {
        let panel: any = [];
        panel = this.http.get<Cotizacion[]>(`${this._basePath}/panel/${indice}`, {
            params: { ...params }
        });
        const panelesConVariaciones = panel.pipe(
            map((p: any) => {
                p.cotizaciones.map(data => {
                    data.variacionAbs = calcularVarAbsoluta(data);
                    data.variacionPorcentual = calcularVarPorcentual(data);
                    data.Tipo = 'latam';
                });
                return p;
            })
        );
        return panelesConVariaciones;
    }
    // Las que se muestran en instrumentos destacados (en home)
    getInstrumentosDestacados(): Observable<Recomendacion> {
        return this.getRecomendacion(`${this._basePath}/recomendaciones/name/general`);
    }

    // Las que se muestran en ranking de instrumentos (en home)
    getRankingTendencias(): Observable<Recomendacion> {
        return this.http.get<Recomendacion>(`${this._basePath}/recomendaciones/name/tendencias`, {
            params: { avoidAuthRedirect: 'true' }
        });
    }

    // Las que se muestran en ranking de instrumentos (en home)
    getRankingMasActivos(): Observable<Recomendacion> {
        return this.http.get<Recomendacion>(`${this._basePath}/recomendaciones/name/mas_activos`, {
            params: { avoidAuthRedirect: 'true' }
        });
    }

    // Las que se muestran en ranking de instrumentos (en home)
    getRankingCedears(): Observable<Recomendacion> {
        return this.http.get<Recomendacion>(`${this._basePath}/recomendaciones/name/ganadores_cedears`, {
            params: { avoidAuthRedirect: 'true' }
        });
    }

    // Las que se muestran en ranking de instrumentos (en home)
    getRankingAccionesYBonos(): Observable<Recomendacion> {
        return this.http.get<Recomendacion>(`${this._basePath}/recomendaciones/name/ganadores_bonos_acciones`, {
            params: { avoidAuthRedirect: 'true' }
        });
    }

    // Las que se muestran en detalle instrumento de tipo Cedears
    getRecomendacionesCedears(): Observable<Recomendacion> {
        return this.getRecomendacion(`${this._basePath}/recomendaciones/name/cedears`);
    }

    // Las que se muestran en detalle instrumento de tipo Bonos
    getRecomendacionesRentaFija(): Observable<Recomendacion> {
        return this.getRecomendacion(`${this._basePath}/recomendaciones/name/bonos`);
    }

    // Las que se muestran en detalle instrumento de tipo Acciones
    getRecomendacionesAcciones(): Observable<Recomendacion> {
        return this.getRecomendacion(`${this._basePath}/recomendaciones/name/acciones`);
    }

    // Las que se muestran en cotizaciones
    getRecomendacionesGenerales(): Observable<Recomendacion> {
        return this.getRecomendacion(`${this._basePath}/recomendaciones/name/recommendation_clusterized/person`);
    }

    sendTickerOpenEvent(operation_data, instrument_type, opt_from): void {
        this._userBehaviourService.sendEvent({
            category: 'instrument_watch',
            name: 'instrument_watch.watch_instrument.detail',
            description: 'Event registered when user enters to the detail of the ticker',
            content: {
                operation_data,
                instrument_type,
                opt_from
            }
        });
    }

    getRecomendacionesByInstrumento(securityId: string, idNegocio: number, limit: number = 3) {
        return this.http.get<Recomendacion>(
            `${this._apiURL}/analytics/recommendations/user/operations?securityid=${securityId}&top_n=${limit}&idNegocio=${idNegocio}`,
            {
                params: { avoidAuthRedirect: true }
            }
        );
    }

    // Servicio que devuelve los ultimos instrumentos visitados
    getInstrumentViews(idNegocio: any, top): Observable<any> {
        return this.http
            .get<InstrumentView[]>(
                `${this._apiUrlInsights}/history/instruments/instrumentsviews?idNegocio=${idNegocio}&top=${top}`,
                {
                    params: { avoidAuthRedirect: true }
                }
            )
            .pipe(
                tap(data => {
                    this.lastViewed$.next(data);
                })
            );
    }

    getCotizacionDolar(idCuenta: number) {
        return this.http.get<any>(`${this._basePath}/dolar/${idCuenta}`);
    }

    getCotizacionMesaCambio(): Observable<MinutasMepInfo> {
        return this.http.get<MinutasMepInfo>(`${this._envService.env.BaseUrlService}/cotizaciones/mesa-cambio`, {
            params: { avoidAuthRedirect: true }
        });
    }
    getLetras(params): Observable<any> {
        return this.http.get<Cotizacion[]>(`${this._basePath}/letras`, { params: { ...params } });
    }
    getOpcionesInDetalleInstrument(plazo, ticker) {
        return this.http.get(`${this._apiURL}/cotizacionopciones?plazo=${plazo}&ticker=${ticker}`);
    }
}
