import { BlzEnvironment } from '../app/core/services/environment.service';

/*
  Si se necesita agregar un nuevo elemento al ambiente, necesitas previamente
  agregar la propiedad a la clase "BlzEnvironment", asi de esta manera siempre
  estarán sincronizados los environment dev,test y prod (de lo contrario mostrará
  error).
*/

export const environment: BlzEnvironment = {
    production: true,
    checkForUpdates: true,
    maintenance: false,
    isCustomerImpersonate: false,
    AbrirCuentaUrl: 'https://test.balanz.com/abrir-cuenta-2.aspx',
    BaseUrlService: '/api/v1',
    BaseUrlWebSocket: 'wss://' + location.host + '/websocket',
    BaseUrlLoggerService: 'https://devba.balanz.com/api/v1/analytics/data-events',
    LoggerAppName: 'balanz-web',
    LoggerEnabled: true,
    UserBehaviourEnabled: true,
    BaseUrlImage: '',
    TV_clientID: 'devbeta.balanz.com',
    TV_urlprefix: '/',
    TV_library: '/widgets/libs/charting_library/latest/',
    BaseUrlWidgetsLibs: '/widgets/components/',
    WidgetTradingView: 'balanz-trading-chart/2.0.1/balanz-trading-chart.js',
    WidgetChatElement: 'chat-clientes/latest/balanz-chat-widget-element.js',
    WidgetFaqsElement: 'balanz-faqs/latest/balanz-faqs.js',
    WidgetCalificacionElement: 'balanz-calificacion/latest/balanz-calificacion.js',
    showWidgetCalificacion: true,
    assetsUrl: 'https://cms.balanz.com/PFS/',
    workerassetsUrl: 'https://devassets.balanz.com',
    minutesForCheckMarket: 5,
    alertsCloseDelay: 4000,
    defaultErrorMsg: 'Este servicio no está disponible, intentá de nuevo en unos minutos.',
    amchartsLicense: 'CH315943706',
    encrypterSecret: 'zumSex8psQkqkUECwSs+8vfYcbUGDuN7F6A347MoM0M=',
    timeRefreshDataMinutes: 10,
    timeDelaySeconds: 5,
    requestsSource: 'WebV2',
    TipoDispositivo: 'Web',
    FaqSupport: true,
    ThemeSupport: true,
    RecomendacionesSupport: true,
    RankingSupport: true,
    DolarMepSupport: true,
    showIdCuenta: false,
    local: false,
    consoleLogging: true,
    showUpdateDialog: false,
    automaticUpdates: true,
    renderItemsCotizacionByScroll: true,
    translateConfig: {
        defaultLang: 'es',
        languages: ['es', 'en'],
        showSelectorInLogged: true,
        showSelectorInUnlogged: true
    },
    showNewViewFormOperar: true,
    showRecommendationsAfterOperation: true,
    showUltimosVistos: true,
    assetsTickersURL: 'https://devassets.balanz.com',
    WidgetGenBotAiUri: 'balanz-ai-chat/latest/balanz-ai-chat-widget-element.js',
    showWidgetGenBotAi: true,
    showSocial: true,
    baseUrlInsights: '/insights/api/v1',
    baseUrlDeeplink: 'https://devbeta.balanz.com',
    showTenenciaDetalleInstrumento: true,
    showBtnCreateWatchlistSeguimiento: true,
    operacionRapidaBuscadorEspecie: true,
    showBtnCreateAlertaSeguimiento: true,
    urlRecomendacionesSeguridad: 'https://devdigital.balanz.com/recomendaciones',
    showAddToFavoritos: false,
    showRangoPreciosDiarios: true,
    showTenenciaDolarizada: true,
    showSentimientoMercado: true,
    enableUndoWatchlist: true,
    showTabOptionsInstrumentDetail: true
};
