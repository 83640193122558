import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Watchlist } from '../../shared/models';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class WatchlistService {
    private apiUrl = this._envService.env.BaseUrlService + '/watchlists';

    constructor(private http: HttpClient, private _envService: EnvironmentService) {}

    getWatchlists(): Observable<Watchlist[]> {
        return this.http.get<Watchlist[]>(this.apiUrl).pipe(map((res: any) => res.watchlists));
    }

    createWatchlist(watchlist): Observable<any> {
        return this.http.post(`${this.apiUrl}`, watchlist, { params: { avoidAuthRedirect: 'true' } }).pipe(
            // TODO: Remover cuando se ajuste backend, actualmente devuelve 200 con CodigoError si no puede crear (15/09/22)
            map((res: any) => {
                if (res?.CodigoError) {
                    throw new Error();
                }
            })
        );
    }
    createWatchlistMultiple(watchlists): Observable<any> {
        return this.http.post(`${this.apiUrl}/multiple`, watchlists, { params: { avoidAuthRedirect: 'true' } }).pipe(
            map((res: any) => {
                if (res?.CodigoError) {
                    throw new Error();
                }
            })
        );
    }
    removeWatchlist(idWatchlist, params?): Observable<any> {
        return this.http.delete(`${this.apiUrl}/${idWatchlist}`, { params });
    }

    eliminarPorTicker(ticker, plazo, idWatchlist): Observable<any> {
        let myParams = new HttpParams();
        myParams = myParams.append('ticker', ticker);
        myParams = myParams.append('plazo', plazo);
        return this.http.delete(`${this.apiUrl}/${idWatchlist}`, {
            params: myParams
        });
    }
    deshacerEliminarWatchlist(idWatchlist) {
        return this.http.post(`${this.apiUrl}/undo/${idWatchlist}`, { params: { avoidAuthRedirect: 'true' } });
    }
    updateWatchlistPosition(watchlist) {
        const position = watchlist.position;
        return this.http.patch(
            `${this.apiUrl}/${watchlist.idWatchlist}`,
            { posicion: position },
            { params: { avoidAuthRedirect: 'true' } }
        );
    }
    editarNombreWatchlist(watchlist) {
        const payload = {
            nombre: watchlist.name,
            descripcion: watchlist.description
        };
        return this.http.patch(
            `${this.apiUrl}/${watchlist.idWatchlist}`,
            { payload },
            { params: { avoidAuthRedirect: 'true' } }
        );
    }
}
