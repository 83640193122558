export const JWTdecode = item => {
    let decoded;
    try {
        decoded = JSON.parse(window.atob(item));
    } catch {
        decoded = null;
    }
    return decoded;
};

export const JWTdecodeUtf8 = item => {
    try {
        const _decoded = b64DecodeUnicode(item);
        return JSON.parse(_decoded);
    } catch {
        return null;
    }
};

const b64DecodeUnicode = str =>
    decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
